<template>
  <div
    :height="$vuetify.breakpoint.xs ? '220px' : '270px'"
    class="mx-md-0 mx-1"
  >
    <v-card
      v-if="card_active"
      elevation="6"
      :height="$vuetify.breakpoint.xs ? '160px' : '230px'"
      class="my-1 mt-md-7 mb-md-4"
    >
      <div style="text-align: center; padding: 10% 0">
        <v-row
          v-if="!active && !activeted"
          style="
            display: flex;
            justify-content: center !important;
            flex-direction: column;
            align-items: center !important;
            position: absolute;
            right: 20px;
            top: 10px;
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                icon
                v-on="on"
                v-bind="attrs"
                :disabled="disabled"
                @click="settingCreateOrUpdate(true)"
                ><v-icon medium>$lock</v-icon></v-btn
              ></template
            >
            <span>{{ $t("activate") }}</span>
          </v-tooltip>
        </v-row>
        <v-row
          v-else
          style="
            display: flex;
            justify-content: center !important;
            flex-direction: column;
            align-items: center !important;
            position: absolute;
            right: 20px !important;
            top: 10px !important;
          "
          ><v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="active && !activeted"
                elevation="0"
                v-on="on"
                v-bind="attrs"
                :disabled="disabled"
                @click="settingCreateOrUpdate(false)"
                icon
                ><v-icon medium>$unlock</v-icon></v-btn
              >
            </template>
            <span>{{ $t("disactivate") }}</span>
          </v-tooltip>
        </v-row>
        <v-col>
          <v-icon
            :color="!disabled ? 'primary' : 'grey'"
            :size="$vuetify.breakpoint.xs ? 50 : 100"
          >
            {{ this.icon }}
          </v-icon>
        </v-col>
        <v-card-title>
          <v-col
            class="pa-0"
            style="text-transform: uppercase"
            v-bind:style="[
              {
                'font-size': $vuetify.breakpoint.xs ? '12px' : '16px',
              },
            ]"
          >
            {{ $t(title) }}
          </v-col>
        </v-card-title>
      </div>
    </v-card>
    <v-dialog
      v-if="help != true"
      :width="this.size != null ? size : '700'"
      v-model="dialog"
    >
      <template v-slot:activator="{ on }">
        <v-row
          style="
            display: flex;
            justify-content: center !important;
            flex-direction: column;
            align-items: center !important;
          "
        >
          <v-btn
            v-if="help != true && card_active"
            :disabled="(!activeted && !active) || disabled"
            elevation="0"
            v-on="on"
            @click="$emit('open')"
            outlined
            style="height: 35px; margin-bottom: 15px"
            class="mt-2"
            >{{ $t("configuration") }}</v-btn
          >
          <v-btn
            v-else-if="help != true && !card_active"
            :disabled="(!activeted && !active) || disabled"
            elevation="0"
            v-on="on"
            block
            height="30px"
            @click="$emit('open')"
            >{{ $t("edit", { name: "" }) }}</v-btn
          >
        </v-row>
      </template>
      <v-card id="newPopup">
        <v-card-title
          style="text-decoration: underline; text-transform: uppercase"
          >{{ title }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions v-if="buttons === true">
          <v-spacer></v-spacer>
          <v-btn
            v-if="edit"
            outlined
            @click="
              edit = false;
              $emit('edit', !edit);
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-if="edit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            @click="save"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
          <v-btn
            v-else
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            @click="
              edit = true;
              $emit('edit', !edit);
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$edit</v-icon>
            {{ $t("edit", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      v-else
      style="
        display: flex;
        justify-content: center !important;
        flex-direction: column;
        align-items: center !important;
      "
    >
      <v-btn
        v-if="!active && !activeted"
        elevation="0"
        outlined
        v-on="on"
        style="height: 35px"
        v-bind="attrs"
        class="mt-2"
        :disabled="disabled"
        @click="settingCreateOrUpdate(true)"
        ><v-icon left small>$lock</v-icon>
        {{ $t("activate") }}
      </v-btn>
      <v-btn
        v-else
        elevation="0"
        outlined
        style="height: 35px"
        v-on="on"
        class="mt-2"
        v-bind="attrs"
        :disabled="disabled"
        @click="settingCreateOrUpdate(false)"
      >
        <v-icon left small>$unlock</v-icon>
        {{ $t("disactivate") }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CardConfiguration",
  props: {
    title: {
      type: String,
      required: true,
    },
    activeted: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    keys: {
      type: String,
      required: true,
    },
    help: {
      type: Boolean,
      required: false,
    },
    buttons: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    only_active: {
      type: Boolean,
      required: false,
    },
    card_active: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dialog: false,
      setting: true,
      active: false,
      edit: false,
    };
  },
  mounted() {
    //Bakend get settiong key
    if (!this.activeted) this.fetchSetting();
    //if (this.icon === "$appointments") this.dialog = true;
  },
  methods: {
    save() {
      this.edit = false;
      this.$emit("edit", !this.edit);
      this.$emit("save");
      this.$alert("Se ha guardado correctamente", "", "");
    },
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    fetchSetting() {
      this.loading = true;
      this.getSetting({ key: this.keys }).then((boxes) => {
        //console.log(boxes.data);

        this.active = JSON.parse(boxes.data.value);
        //console.log(this.active);
        this.loading = false;
      });
    },
    settingCreateOrUpdate(item) {
      if (item === true) {
        //console.log(this.keys);
        //console.log(item);
        this.settingUpdate({
          key: this.keys,
          value: item,
        }).then((data) => {
          this.fetchSetting();
          this.$alert(
            this.$t("activated_correct", { name: this.title }),
            "",
            ""
          );
        });
      } else {
        this.$confirm(
          this.$t("disactivate_info", { name: this.title }),
          "",
          ""
        ).then(() => {
          //console.log(this.keys);
          //console.log(item);
          this.settingUpdate({
            key: this.keys,
            value: item,
          }).then((data) => {
            this.fetchSetting();
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="sass">
#newPopup
</style>
