var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-md-0 mx-1",attrs:{"height":_vm.$vuetify.breakpoint.xs ? '220px' : '270px'}},[(_vm.card_active)?_c('v-card',{staticClass:"my-1 mt-md-7 mb-md-4",attrs:{"elevation":"6","height":_vm.$vuetify.breakpoint.xs ? '160px' : '230px'}},[_c('div',{staticStyle:{"text-align":"center","padding":"10% 0"}},[(!_vm.active && !_vm.activeted)?_c('v-row',{staticStyle:{"display":"flex","justify-content":"center !important","flex-direction":"column","align-items":"center !important","position":"absolute","right":"20px","top":"10px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.settingCreateOrUpdate(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("$lock")])],1)]}}],null,false,1900396746)},[_c('span',[_vm._v(_vm._s(_vm.$t("activate")))])])],1):_c('v-row',{staticStyle:{"display":"flex","justify-content":"center !important","flex-direction":"column","align-items":"center !important","position":"absolute","right":"20px !important","top":"10px !important"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.active && !_vm.activeted)?_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","disabled":_vm.disabled,"icon":""},on:{"click":function($event){return _vm.settingCreateOrUpdate(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("$unlock")])],1):_vm._e()]}}],null,false,163771505)},[_c('span',[_vm._v(_vm._s(_vm.$t("disactivate")))])])],1),_c('v-col',[_c('v-icon',{attrs:{"color":!_vm.disabled ? 'primary' : 'grey',"size":_vm.$vuetify.breakpoint.xs ? 50 : 100}},[_vm._v(" "+_vm._s(this.icon)+" ")])],1),_c('v-card-title',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-transform":"uppercase"},style:([
            {
              'font-size': _vm.$vuetify.breakpoint.xs ? '12px' : '16px',
            } ])},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])],1)],1)]):_vm._e(),(_vm.help != true)?_c('v-dialog',{attrs:{"width":this.size != null ? _vm.size : '700'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-row',{staticStyle:{"display":"flex","justify-content":"center !important","flex-direction":"column","align-items":"center !important"}},[(_vm.help != true && _vm.card_active)?_c('v-btn',_vm._g({staticClass:"mt-2",staticStyle:{"height":"35px","margin-bottom":"15px"},attrs:{"disabled":(!_vm.activeted && !_vm.active) || _vm.disabled,"elevation":"0","outlined":""},on:{"click":function($event){return _vm.$emit('open')}}},on),[_vm._v(_vm._s(_vm.$t("configuration")))]):(_vm.help != true && !_vm.card_active)?_c('v-btn',_vm._g({attrs:{"disabled":(!_vm.activeted && !_vm.active) || _vm.disabled,"elevation":"0","block":"","height":"30px"},on:{"click":function($event){return _vm.$emit('open')}}},on),[_vm._v(_vm._s(_vm.$t("edit", { name: "" })))]):_vm._e()],1)]}}],null,false,382095843),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"newPopup"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._t("default")],2),(_vm.buttons === true)?_c('v-card-actions',[_c('v-spacer'),(_vm.edit)?_c('v-btn',{staticStyle:{"height":"25px","width":"100px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){_vm.edit = false;
            _vm.$emit('edit', !_vm.edit);}}},[_vm._v(_vm._s(_vm.$t("cancel")))]):_vm._e(),(_vm.edit)?_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0"},on:{"click":_vm.save}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: _vm.$t("") }))+" ")],1):_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0"},on:{"click":function($event){_vm.edit = true;
            _vm.$emit('edit', !_vm.edit);}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$edit")]),_vm._v(" "+_vm._s(_vm.$t("edit", { name: _vm.$t("") }))+" ")],1)],1):_vm._e()],1)],1):_c('v-row',{staticStyle:{"display":"flex","justify-content":"center !important","flex-direction":"column","align-items":"center !important"}},[(!_vm.active && !_vm.activeted)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",staticStyle:{"height":"35px"},attrs:{"elevation":"0","outlined":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.settingCreateOrUpdate(true)}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$lock")]),_vm._v(" "+_vm._s(_vm.$t("activate"))+" ")],1):_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",staticStyle:{"height":"35px"},attrs:{"elevation":"0","outlined":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.settingCreateOrUpdate(false)}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$unlock")]),_vm._v(" "+_vm._s(_vm.$t("disactivate"))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }